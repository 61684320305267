var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"email-details"}},[(_vm.isLoading)?[_c('LoadingSpinner',{attrs:{"text":"Loading Email..."}})]:_c('div',{staticClass:"content"},[_c('div',{staticClass:"email-details__split-container"},[_c('div',{staticClass:"email-details__split-container"},[_c('div',{staticClass:"email-details__split-content",style:(_vm.screenWidth > 767
              ? { width: _vm.leftWidth + 'px' }
              : { height: _vm.leftWidth + 'px' })},[_c('div',[_c('div',[_c('div',{staticClass:"quote-content"},[_c('TheHeader',{attrs:{"id":_vm.selectedEmail.id,"title":_vm.infoTitle,"subject":_vm.selectedEmail.subject}}),_c('div',{staticClass:"centered"},[_c('OptionButton',{staticClass:"option-button",attrs:{"title":_vm.stickyContentStatus},nativeOn:{"click":function($event){return _vm.getEmailContent($event)}}}),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.nonLtlbusy,"rounded":"","opacity":"0.6","spinner-small":"","bg-color":"#ebecec"}},[(
                        (_vm.editable &&
                          _vm.infoQuote.data.client_id !== 2 &&
                          _vm.infoQuote.data.client_id !== 3) ||
                          (_vm.rolUser &&
                            _vm.rolUser.mode === 'admin' &&
                            _vm.$route.params.type.toLowerCase() === 'ner')
                      )?_c('OptionButton',{staticClass:"option-button",attrs:{"title":"Non-LTL"},nativeOn:{"click":function($event){return _vm.sendToNonLtl($event)}}}):_vm._e()],1),(_vm.isNonLtl)?_c('OptionButton',{staticClass:"option-button",attrs:{"title":"Send to LTL"},nativeOn:{"click":function($event){return _vm.sendToLtl($event)}}}):_vm._e(),_c('OptionButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.createNewTripButton),expression:"createNewTripButton"}],staticClass:"option-button",attrs:{"title":"Create a Trip"},nativeOn:{"click":function($event){return _vm.createNewTrip($event)}}})],1),(
                    _vm.selectedEmail.tagsEmail &&
                      (_vm.$route.params.type == 'ner' ||
                        _vm.$route.params.type == 'failed' ||
                        _vm.$route.params.type == 'sent')
                  )?_c('div',{staticClass:"quote-content__tags"},_vm._l((_vm.selectedEmail.tagsEmail),function(tag){return _c('b-alert',{key:tag,attrs:{"variant":"info","show":""}},[_c('b-icon',{attrs:{"icon":"tags-fill","variant":"info"}}),_vm._v(" "+_vm._s(tag))],1)}),1):_vm._e(),_c('div',{staticClass:"trip-list"},[_c('b-tabs',[_vm._l((_vm.selectedEmail.trips),function(trip,index){return [_c('b-tab',{key:'dyn-tab-' + index,attrs:{"active":_vm.lengthArrayTrips - 1 == index ? true : false,"title":'Trip ' + (index + 1)}},[_c('TripPanel',{key:index,attrs:{"type":_vm.type,"editable":_vm.editable,"id":trip.id,"index":index}})],1)]})],2)],1),(_vm.editable && _vm.infoQuote.data.client_id == 2)?_c('CannotQuote',{attrs:{"selectedEmail":_vm.selectedEmail}}):_vm._e(),(_vm.editable)?_c('div',{staticClass:"confirm-section mx-auto d-flex justify-content-center"},[(
                      _vm.infoQuote.data.client_id == 1 ||
                        _vm.infoQuote.data.client_id == 4
                    )?_c('div',{staticClass:"\n              confirm-container\n              d-flex\n              justify-content-center\n              align-items-center\n            "},[_c('ToggleSwitch',{staticClass:"incomplete mr-2",model:{value:(_vm.incomplete),callback:function ($$v) {_vm.incomplete=$$v},expression:"incomplete"}}),_vm._m(0)],1):_vm._e(),_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.sendQuoteBusy,"rounded":"","opacity":"0.6","bg-color":"#ebecec","spinner-small":""}},[_c('OptionButton',{attrs:{"title":"send quote","disabled":_vm.sendingQuote},nativeOn:{"click":function($event){return _vm.confirm($event)}}})],1)],1):_vm._e()],1)])])]),_c('div',{staticClass:"email-details__splitter",on:{"mousedown":_vm.startDragging}}),_c('div',{staticClass:"email-details__right-panel",style:({ width: _vm.rightWidth + 'px' })},[_c('StickyEmail',{attrs:{"content":_vm.stickyContent}})],1)])])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',[_c('strong',[_vm._v("Incomplete")])])}]

export { render, staticRenderFns }