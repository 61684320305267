<template>
  <div class="sticky-email">
    <div class="sticky-email__attachments">
      <Attachments infoPrincipalView="sticky" />
    </div>
    <hr />
    <div class="dragbar" ref="containerHtml">
      <div class="sticky-content">
        {{ content }}
      </div>
      <!-- <ContentPanel /> -->
      <!-- delete in next commit -->
      <!-- <iframe
        title="Sticky Email"
        class="sticky-iframe"
        ref="stickyIframe"
      ></iframe> -->
    </div>
  </div>
</template>

<script>
import Attachments from "./Attachments.vue";
// import ContentPanel from "@/components/ContentPanel.vue";

export default {
  name: "StickyEmail",
  components: {
    Attachments
    // ContentPanel
  },
  props: {
    content: String
  },
  data() {
    return {
      containerHtml: null,
      changeContent: true
    };
  },
  watch: {
    content() {
      this.changeContent = !this.changeContent;
      this.renderContent();
    }
  },
  methods: {
    renderContent() {
      // delete in next commit
      /* const iframeDocument = this.$refs.stickyIframe.contentDocument;
      iframeDocument.open();
      iframeDocument.write(this.content);
      iframeDocument.close(); */
      let replaceStyle = this.content;
      if (!this.changeContent) {
        replaceStyle = this.content.replaceAll(
          /<style[^>]*>.*?<\/style>/gs,
          ""
        );
      }
      this.containerHtml = this.$refs.containerHtml;
      this.containerHtml.innerHTML = replaceStyle;
    }
  },
  mounted: function() {
    this.renderContent();
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sticky-email,
.dragbar {
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: start;
  @media (max-width: 767px) {
    height: 250px;
    border: 1px solid #bbbbbb;
  }
  &__attachments {
    background-color: white;
  }
}

.sticky-content {
  position: fixed;
  overflow-y: scroll;
  overflow-x: auto;
  height: 90%;
  padding: 2rem 2rem 4rem;
  min-width: -webkit-fill-available;
  text-align: start;
  @media (max-width: 767px) {
    position: static;
    padding: 1rem;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 20px;
  }
}

::v-deep pre {
  white-space: break-spaces;
}
</style>
