<template>
  <div class="custom-control custom-checkbox">
    <input
      :id="id"
      type="checkbox"
      class="custom-control-input"
      :checked="checked"
      @change="updateModel"
    />
    <label class="custom-control-label" :for="id"><slot></slot></label>
  </div>
</template>

<script type="text/javascript">
import { v4 as uuidv4 } from "uuid";

export default {
  name: "CustomCheck",
  props: {
    id: {
      type: String,
      default: uuidv4()
    },
    value: Boolean
  },
  data() {
    return {
      checked: this.value
    };
  },
  methods: {
    updateModel() {
      this.checked = !this.checked;
      this.$emit("input", this.checked);
    }
  },
  created() {
    if (!this.id) this.id = uuidv4();
  }
};
</script>

<style scoped>
label {
  user-select: none;
  margin: 5px;
  color: #666666;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--button-bg-color);
  box-shadow: 0 0 3px var(--button-hover-color);
  border: none;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 3px var(--button-bg-color);
  border: none;
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  box-shadow: 0 0 3px var(--button-bg-color);
  background: var(--button-bg-color);
}
</style>
