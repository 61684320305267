<template>
  <div id="email-details">
    <template v-if="isLoading">
      <LoadingSpinner text="Loading Email..." />
    </template>
    <div v-else class="content">
      <div class="email-details__split-container">
        <div class="email-details__split-container">
          <div
            class="email-details__split-content"
            :style="
              screenWidth > 767
                ? { width: leftWidth + 'px' }
                : { height: leftWidth + 'px' }
            "
          >
            <div>
              <div>
                <div class="quote-content">
                  <TheHeader
                    :id="selectedEmail.id"
                    :title="infoTitle"
                    :subject="selectedEmail.subject"
                  />
                  <div class="centered">
                    <OptionButton
                      class="option-button"
                      :title="stickyContentStatus"
                      @click.native="getEmailContent"
                    />
                    <b-overlay
                      :show="nonLtlbusy"
                      rounded
                      opacity="0.6"
                      spinner-small
                      bg-color="#ebecec"
                      class="d-inline-block"
                    >
                      <OptionButton
                        class="option-button"
                        title="Non-LTL"
                        v-if="
                          (editable &&
                            infoQuote.data.client_id !== 2 &&
                            infoQuote.data.client_id !== 3) ||
                            (rolUser &&
                              rolUser.mode === 'admin' &&
                              $route.params.type.toLowerCase() === 'ner')
                        "
                        @click.native="sendToNonLtl"
                      />
                    </b-overlay>
                    <OptionButton
                      class="option-button"
                      title="Send to LTL"
                      v-if="isNonLtl"
                      @click.native="sendToLtl"
                    />
                    <OptionButton
                      class="option-button"
                      title="Create a Trip"
                      v-show="createNewTripButton"
                      @click.native="createNewTrip"
                    />
                  </div>
                  <!-- <div id="content-panel-wrapper">
                    <ContentPanel class="content-panel" />
                  </div> -->
                  <div
                    class="quote-content__tags"
                    v-if="
                      selectedEmail.tagsEmail &&
                        ($route.params.type == 'ner' ||
                          $route.params.type == 'failed' ||
                          $route.params.type == 'sent')
                    "
                  >
                    <b-alert
                      v-for="tag in selectedEmail.tagsEmail"
                      :key="tag"
                      variant="info"
                      show
                      ><b-icon icon="tags-fill" variant="info"></b-icon>
                      {{ tag }}</b-alert
                    >
                  </div>
                  <div class="trip-list">
                    <b-tabs>
                      <template v-for="(trip, index) in selectedEmail.trips">
                        <b-tab
                          :active="lengthArrayTrips - 1 == index ? true : false"
                          :title="'Trip ' + (index + 1)"
                          :key="'dyn-tab-' + index"
                        >
                          <TripPanel
                            :key="index"
                            :type="type"
                            :editable="editable"
                            :id="trip.id"
                            :index="index"
                          />
                        </b-tab>
                      </template>
                    </b-tabs>
                  </div>
                  <CannotQuote
                    v-if="editable && infoQuote.data.client_id == 2"
                    :selectedEmail="selectedEmail"
                  />
                  <div
                    v-if="editable"
                    class="confirm-section mx-auto d-flex justify-content-center"
                  >
                    <div
                      class="
                confirm-container
                d-flex
                justify-content-center
                align-items-center
              "
                      v-if="
                        infoQuote.data.client_id == 1 ||
                          infoQuote.data.client_id == 4
                      "
                    >
                      <ToggleSwitch
                        v-model="incomplete"
                        class="incomplete mr-2"
                      />
                      <label><strong>Incomplete</strong></label>
                    </div>

                    <!--  <div
              class="
                confirm-container
                d-flex
                justify-content-center
                align-items-center
              "
              v-else
            >
              <b-overlay
                :show="sendingIncomplete"
                rounded
                opacity="0.6"
                bg-color="#ebecec"
                spinner-small
                class="d-inline-block"
              >
                <ToggleSwitch
                  :key="renderIncompleteGlt"
                  v-model="incompleteGlt"
                  @input="incompleteQuoteGlt()"
                  class="incomplete mr-2"
                />
              </b-overlay>
              <label><strong>Incomplete</strong></label>
            </div> -->
                    <b-overlay
                      :show="sendQuoteBusy"
                      rounded
                      opacity="0.6"
                      bg-color="#ebecec"
                      spinner-small
                      class="d-inline-block"
                    >
                      <OptionButton
                        title="send quote"
                        @click.native="confirm"
                        :disabled="sendingQuote"
                      />
                    </b-overlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="email-details__splitter" @mousedown="startDragging"></div>
          <div
            :style="{ width: rightWidth + 'px' }"
            class="email-details__right-panel"
          >
            <StickyEmail :content="stickyContent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as api from "@/api/services";
import * as auth from "@/middlewares/auth";
import * as shared from "@/utils/shared";
// eslint-disable-next-line no-unused-vars
import { getStatusByType, validEmail } from "@/utils/helpers";
import tmsConfig from "@/utils/tmsConfig";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import TheHeader from "@/components/TheHeader.vue";
import OptionButton from "@/components/OptionButton.vue";
// import ContentPanel from "@/components/ContentPanel.vue";
import TripPanel from "@/components/TripPanel.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import StickyEmail from "@/components/StickyEmail.vue";
import CannotQuote from "@/components/CannotQuote.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "EmailDetails",
  props: {
    id: String,
    type: {
      type: String,
      default: "details"
    }
  },
  components: {
    LoadingSpinner,
    TheHeader,
    OptionButton,
    // ContentPanel,
    TripPanel,
    ToggleSwitch,
    StickyEmail,
    CannotQuote
  },
  data() {
    return {
      setInterval: null,
      infoQuote: null,
      countQuotes: null
    };
  },
  watch: {
    htmlFinished() {
      this.getEmailContent(true);
    },

    newTripButton() {
      this.createNewTripButton = this.newTripButton;
    },

    selectedEmail: {
      deep: true,
      handler() {
        this.lengthArrayTrips = this.selectedEmail.trips.length;
      }
    }
  },
  computed: {
    ...mapState("emailDetails", [
      "newTripButton",
      "selectedEmail",
      "htmlFinished"
    ]),
    ...mapGetters("emailDetails", ["getTrip", "getClientId"]),
    trip: function() {
      return this.getTrip(this.id);
    },
    infoTitle: function() {
      return this.selectedEmail.date;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    editable() {
      let rolUser = this.$store.getters["user/showRolUser"];
      if (rolUser.mode !== "admin") {
        return ["ner", "failed"].includes(this.type);
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.editable = false;
      }
    },
    rightWidth() {
      return window.innerWidth - this.leftWidth - 8;
    }
  },
  mounted() {
    document.body.classList.add("my-custom-class");
  },
  beforeDestroy() {
    document.body.classList.remove("my-custom-class");
  },
  // eslint-disable-next-line no-dupe-keys
  data: function() {
    return {
      isLoading: true,
      stickyContent: String,
      stickyContentState: Number,
      stickyContentStatus: String,
      tmsName: tmsConfig.tmsName,
      incomplete: false,
      incompleteGlt: false,
      isNonLtl: false,
      createNewTripButton: false,
      nonLtlbusy: false,
      sendQuoteBusy: false,
      tabs: [1],
      deleteTab: 0,
      lengthArrayTrips: null,
      sendingQuote: false,
      sendingIncomplete: false,
      renderIncompleteGlt: 0,
      leftWidth: 900,
      screenWidth: null,
      rolUser: null
    };
  },
  methods: {
    ...mapActions("emailList", ["deleteEmails"]),
    ...mapActions("emailDetails", [
      "getEmailById",
      "addTrip",
      "deleteTrip",
      "toNonLtl"
    ]),
    ...mapMutations("user", ["logoutUser", "setUserStatus"]),
    toggleSidebar: function() {
      const sidebar = this.$children.find((child) => {
        return child.$options.name === "TheSidebar";
      });
      sidebar.toggleSidebar();
    },
    //method to show emails original and processed email in the sticky panel
    newTrip() {
      this.$store.commit("emailDetails/setNewTrip");
    },
    deleteTrip() {
      this.deleteTab += 1;
    },
    async createNewTrip() {
      if (this.editable) {
        await this.addTrip(this.selectedEmail.id);
        this.$store.commit("emailDetails/newTripButton", false);
      } else {
        Swal.fire(
          "Oops...",
          `You cannot add trips in ${this.type} mode`,
          "error"
        );
      }
    },
    getEmailContent(state) {
      if (state === true) {
        if (this.selectedEmail.html === "") {
          this.stickyContentState = 1;
        } else {
          this.stickyContentState = 0;
        }
      }
      let content = "";
      if (this.stickyContentState === 0) {
        content = this.selectedEmail.html;
        this.stickyContentState = 1;
        this.stickyContentStatus = "Processed Email";
      } else if (this.stickyContentState === 1) {
        content =
          "<div style='font-family: Courier New, monospace;'>" +
          this.selectedEmail?.message +
          "</div>";
        this.stickyContentState = 0;
        this.stickyContentStatus = "Original Email";
      }
      this.stickyContent = content;
    },
    async sendToLtl() {
      let quoteId = this.$route.params.id;
      let response = await this.$store.dispatch(
        "emailDetails/sendToLtl",
        quoteId
      );
      if (response.status === 204) {
        Swal.fire({
          title: "Great!",
          text: `Email sent to LTL successfully.`,
          icon: "success"
        }).then(() => {
          this.$router.push({ name: "emailList", params: { type: "nonLtl" } });
        });
      }
    },
    async sendToNonLtl() {
      let currentId;
      this.nonLtlbusy = true;
      try {
        if (!this.id) {
          currentId = this.selectedEmail.id;
          await this.toNonLtl(currentId);
        } else {
          await this.toNonLtl(this.id);
        }
        if (this.state === "true") {
          Swal.fire({
            title: "Great!",
            text: `Email sent to Non-LTL successfully.`,
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Assign another quote",
            cancelButtonText: "Change status to busy"
          }).then((value) => {
            this.nonLtlbusy = false;
            if (!value.isConfirmed) {
              this.changeUserStatus();
            } else {
              clearInterval(this.setInterval), this.reloadPage();
            }
          });
        } else if (this.state === "false") {
          history.go(-1);
        }
      } catch (err) {
        console.error(err.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Could not delete email from database: ${err.message}`
        });
      }
    },
    validate: function() {
      let [valid, message] = shared.validateMissingEntities(
        this.selectedEmail.trips,
        this.infoQuote.data.client_id,
        this.infoQuote.data.canned_id
      );
      // this.selectedEmail.trips.forEach((trip) => {
      //   if (
      //     !validEmail(trip.pickup_request.email) ||
      //     !validEmail(trip.delivery_request)
      //   ) {
      //     valid = false;
      //     message = "Invalid pickup request/delivery request email";
      //   }
      // });
      // If the quote is incomplete we must send the quote to the tms
      if (!valid) valid = this.incomplete.checked;
      return [valid, message];
    },
    async reloadPage() {
      await this.$router.push({ name: "home" });
      this.$router.push({ name: "details", params: { type: "ner" } });
    },
    async confirm() {
      //validation function before sending data
      const [valid, message] = this.validate();
      if (this.incomplete || valid) {
        this.sendQuoteBusy = true;
        this.sendQuote();
      } else
        Swal.fire({
          icon: "warning",
          title: "Oops... Missing required entities",
          text: message
        });
    },
    async getCountQuotes() {
      let userId = this.$store.getters["user/showRolUser"];
      let arrayStatus = [6, 7, 8, 10];
      let statusList = "?";
      let params = {};
      arrayStatus.forEach((status) => {
        statusList += "status_list=" + status + "&";
      });
      params.user_id = userId.user_id;
      params.status_list = statusList;
      let response = await this.$store.dispatch(
        "emailDetails/getCountQuotes",
        params
      );
      this.countQuotes = response.data.count;
    },
    async changeUserStatus() {
      this.setUserStatus("busy");
      let userId = this.$store.getters["user/showRolUser"];
      let body = {};
      body.id = userId.user_id;
      body.is_busy = true;
      this.$router.push({ name: "home" });
      await this.$store.dispatch("user/updateUserStatus", body);
    },
    // sends the quote to the tms API
    sendQuote: async function() {
      await this.getCountQuotes();
      let currentId;
      try {
        const payload = {
          hauls: this.selectedEmail.trips,
          incomplete: this.incomplete,
          client_id: this.infoQuote.data.client_id,
          canned_id: this.infoQuote.data.canned_id
        };
        if (!this.id) {
          currentId = this.selectedEmail.id;
          await api.sendQuote(currentId, payload);
        } else {
          await api.sendQuote(this.id, payload);
        }
        this.sendQuoteBusy = false;
        Swal.fire({
          icon: "success",
          title: "Great!",
          text: `Quote sent to ${tmsConfig.tmsName} successfully. You have made ${this.countQuotes} quotes today, congratulations! `,
          showCancelButton: true,
          confirmButtonText: "Assign another quote",
          cancelButtonText: "Change status to busy",
          allowOutsideClick: false
        }).then((value) => {
          clearInterval(this.setInterval);
          if (!value.isConfirmed) {
            this.changeUserStatus();
          } else {
            clearInterval(this.setInterval), this.reloadPage();
          }
        });
      } catch (err) {
        console.error(err.message);
        if (err.response && err.response.status === 403) {
          const errorMessage = err.response.data.detail;
          this.logoutUser();
          auth.logout();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errorMessage
          }).then(() => {
            this.$router.push({ name: "login", params: { noAuth: true } });
          });
        } else
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `Could not send data to ${tmsConfig.tmsName}: ${err}`
          });
      }
    },
    async alertControl(minutes) {
      let deltaAlert = this.infoQuote.data.delta.alert;
      let autoQuoteAlert = this.infoQuote.data.delta.autoquote;
      let rest = autoQuoteAlert - deltaAlert;
      let route = this.$route.params.type;
      if (
        minutes >= deltaAlert &&
        minutes < autoQuoteAlert &&
        route === "ner"
      ) {
        Swal.fire({
          icon: "warning",
          title: "Time is running out",
          text: `You have ${rest} minutes to finish the quote`,
          showCancelButton: true,
          confirmButtonText: "Continue",
          cancelButtonText: "Send to Autoquote",
          allowOutsideClick: false
        }).then((value) => {
          if (!value.isConfirmed) {
            this.sendToAutoQuote();
            this.sendToAutoQuoteAlert();
          }
        });
      } else if (minutes > autoQuoteAlert && route === "ner") {
        await this.sendToAutoQuote();
        Swal.fire({
          icon: "warning",
          title: "The time is over",
          text: `The quote has been sent to the autoquoter`
        }).then(() => this.sendToAutoQuoteAlert());
      }
    },

    sendToAutoQuoteAlert() {
      Swal.fire({
        icon: "success",
        title: "Great!",
        text: `The quote has been successfully send to the autoquoter`,
        showCancelButton: true,
        confirmButtonText: "Assign another quote",
        cancelButtonText: "Change status to busy",
        allowOutsideClick: false
      }).then((value) => {
        if (!value.isConfirmed) {
          this.changeUserStatus();
        } else {
          clearInterval(this.setInterval), this.reloadPage();
        }
      });
    },

    async sendToAutoQuote() {
      let customId = localStorage.getItem("custom_id");
      const payload = {
        hauls: this.selectedEmail.trips,
        incomplete: true,
        customId: customId,
        client_id: this.infoQuote.data.client_id
      };
      await this.$store.dispatch("emailDetails/getSendToAutoquoter", payload);
    },
    async currenTime() {
      let currentDate = new Date();
      let initialDate = new Date(this.infoQuote.data.created_at);
      let timeSubtraction = currentDate.getTime() - initialDate.getTime();
      let result = Math.round(timeSubtraction / (1000 * 60));
      const a = this.infoQuote.data.custom_id.indexOf("GLT");
      if (a === -1 && this.getClientId !== 2) {
        await this.alertControl(result);
      }
    },
    async getEmailAssing() {
      this.infoQuote = await this.$store.dispatch(
        "emailDetails/getEmailAssing"
      );
      if (this.infoQuote) {
        if (this.infoQuote.status === 200) {
          localStorage.setItem("custom_id", this.infoQuote.data.custom_id);
          localStorage.setItem("client_id", this.infoQuote.data.client_id);
          this.getEmailContent();
          this.isLoading = false;
          // function to auto-assign the quotation if the user takes too long to make it
          // if (this.getClientId === 1) {
          //   this.interval();
          // }
        }
      } else {
        this.$router.push({
          name: "home"
        });
      }
    },
    // function to auto-assign the quotation if the user takes too long to make it
    // interval() {
    //   let statusEmailAssign = this.$store.getters[
    //     "emailDetails/showStatusEmailAssign"
    //   ];
    //   if (statusEmailAssign !== "9") {
    //     this.setInterval = setInterval(this.currenTime, 30000);
    //   }
    // },
    incompleteQuoteGlt() {
      if (this.incompleteGlt) {
        this.sendingQuote = true;
        this.sendingIncomplete = true;
        this.sendIncomplete();
      } else {
        this.sendingQuote = false;
      }
    },
    async sendIncomplete() {
      let customId = localStorage.getItem("custom_id");
      let info = {};
      info.body = {};
      info.customId = customId;
      info.body.canned_id = this.infoQuote.data.canned_id;
      info.body.client_id = this.infoQuote.data.client_id;
      info.body.incomplete = this.incompleteGlt;
      info.body.hauls = this.infoQuote.data.haul;
      let response = await this.$store.dispatch(
        "incompleteHardfailGlt/sendQuoteIncomplete",
        info
      );
      if (response.status === 204) {
        this.$router.push({ name: "home", params: { type: "home" } });
      } else {
        this.sendingQuote = false;
        this.incompleteGlt = false;
        this.renderIncompleteGlt += 1;
      }
      this.sendingIncomplete = false;
    },
    startDragging(e) {
      this.isDragging = true;
      this.startX = e.clientX;
      document.addEventListener("mousemove", this.handleDragging);
      document.addEventListener("mouseup", this.stopDragging);
    },
    handleDragging(e) {
      if (this.isDragging) {
        const dx = e.clientX - this.startX;
        this.leftWidth += dx;
        this.startX = e.clientX;
      }
    },
    stopDragging() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.handleDragging);
      document.removeEventListener("mouseup", this.stopDragging);
    },
    onReSize() {
      this.screenWidth = window.innerWidth;
    }
  },
  async created() {
    this.$store.commit("user/tokenValidation");
    this.$route.params.type === "nonLtl"
      ? (this.isNonLtl = true)
      : (this.stickyContentState = 0);
    this.stickyContentStatus = "Processed Email";
    this.state = localStorage.getItem("isEmailAssign");
    let currentId;
    let payload;
    if (this.state === "true") {
      await this.getEmailAssing();
    } else {
      try {
        this.isLoading = true;
        if (!this.id) {
          currentId = localStorage.getItem("custom_id");
          payload = {
            id: currentId,
            status: getStatusByType(this.type)
          };
        } else {
          payload = {
            id: this.id,
            status: getStatusByType(this.type)
          };
        }
        await this.getEmailById(payload);
        if (!this.selectedEmail.isValid) throw new Error("invalid email");
        this.isLoading = false;
        this.getEmailContent(true);
      } catch (err) {
        const statusCode = err.response ? err.response.status : 500;
        if (statusCode === 404) err.message = "Email not found";
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Could not get response from Charlie: ${err.message}`
        }).then(() =>
          this.$router.push({
            name: "home"
          })
        );
      }
    }
    this.lengthArrayTrips = this.selectedEmail.trips.length;
    localStorage.setItem("client_id", this.getClientId);
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", this.onReSize);
    this.rolUser = this.$store.getters["user/showRolUser"];
  },
  destroyed() {
    // clearInterval(this.setInterval);
    this.$store.commit("emailDetails/newTripButton", false);
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.content {
  height: 100%;
}
.email-details {
  &__subject-title {
    margin: 2rem 0 1rem 0;
  }
  &__split-container {
    width: 100%;
    height: 82vh;
    display: flex;
  }
  &__split-content {
    padding: 0.5rem;
    border: 1px solid #ccc;
    overflow-y: scroll;
  }
  &__splitter {
    width: 10px;
    cursor: col-resize;
    background: #ddd;
  }
  &__right-panel {
    width: 100%;
    height: 100%;
    margin: 0.5rem;
    overflow: auto;
  }
  &__split-content::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &__split-content::-webkit-scrollbar-track {
    background-color: rgb(218, 218, 218);
  }
  &__split-content::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 20px;
  }
}
::v-deep .btn-outline-danger {
  color: white;
  padding: 2px;
  border-color: #af71b3;
  background: #af71b3;
  border-radius: 7px;
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  outline: none;

  &:enabled {
    transition: all 0.1s ease;

    &:hover {
      background-color: var(--button-hover-color);
      border-color: var(--button-hover-color);
      transform: scale(1.05);
    }
  }
}

.quote-content {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  &__tags {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.centered {
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
::v-deep .option-button {
  & > button.button {
    background-color: #00557e;
    padding: 10px 10px;
  }
}

.alert {
  padding: 0.4rem 0.4rem;
  margin: 0.1rem;
  font-size: 12px;
  font-weight: 400;
}

::v-deep .nav-tabs .nav-link.active {
  color: #af71b3;
  background-color: #fff;
  border: 1px solid #a3a3a3;
  border-bottom: none;
}
.trip-list b {
  color: #666666;
  margin: 10px;
}

.title > p {
  padding-bottom: 20px;
}

.header-separator {
  margin-bottom: 10px;
}
.option-button {
  margin: 5px;
}

.content-panel-wrapper {
  margin: 20px 0;
}

.trip-panel {
  margin-bottom: 60px;
  padding-top: 0px;
  transition: all 0.5s ease-in-out;
}
.confirm-section {
  color: #333;
}

.confirm-container {
  width: 200px;
}

.confirm-section > * {
  padding: 10px;
}
::v-deep .p-splitter-gutter {
  background-color: rgb(218, 218, 218);
  @media (max-width: 767px) {
    display: none;
  }
}
::v-deep .p-splitter-panel.p-splitter-panel-nested {
  min-width: 40%;
}
/* MEDIA QUERIES: RESPONSIVE DESIGN */
@media (max-width: 767px) {
  .email-details {
    &__split-container {
      flex-direction: column-reverse;
    }
    &__right-panel {
      height: auto;
      width: 100% !important;
    }
    &__split-content {
      height: 100% !important;
    }
  }
  .content {
    margin-right: 0;
  }
}
</style>
