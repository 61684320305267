<template>
  <div class="accessorials-card">
    <div class="card-title">
      <font-awesome-icon
        class="link-icon card-title__icon"
        icon="map-marker-alt"
      />
      <h5>{{ category }}</h5>
    </div>
    <div class="card-meta">
      <input
        type="text"
        :disabled="!editable"
        :value="meta.zipcode"
        class="card-input zip-input"
        placeholder="Zipcode..."
        maxlength="8"
        :name="category === 'pickup' ? 'zip_from' : 'zip_to'"
        @click="$event.target.select()"
        @keyup.enter="updateInput($event.target)"
        @keydown.tab="updateInput($event.target)"
      />
      <input
        :id="'city-from-id' + id + '-' + category"
        type="text"
        :disabled="!editable"
        :value="meta.city"
        class="card-input city-input"
        placeholder="City, State..."
        :name="category === 'pickup' ? 'city_from' : 'city_to'"
        @click="$event.target.select()"
        @keyup.enter="updateInput($event.target)"
        @keydown.tab="updateInput($event.target)"
      />
    </div>
    <div class="card-accessorials-list">
      <template v-for="(acc, index) in accessorials">
        <CustomCheckbox
          :key="index"
          :category="category"
          :editable="editable"
          class="card-checkbox delivery-acc"
          :text="acc"
          :currentTrip="currentTrip"
          @change.native="accessorialChange($event.target)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import * as api from "@/api/services";
import { mapGetters, mapMutations } from "vuex";
import CustomCheckbox from "@/components/CustomCheckbox";

export default {
  name: "AccessorialsCard",
  components: {
    CustomCheckbox
  },
  data() {
    return {
      cityInput: null
    };
  },
  props: {
    id: Number,
    category: String,
    meta: Object,
    accessorials: Array,
    editable: Boolean,
    currentTrip: Object
  },
  computed: {
    ...mapGetters("emailDetails", ["getTrip", "selectedEmail"]),
    trip: function() {
      return this.getTrip(this.id);
    }
  },
  methods: {
    ...mapMutations("emailDetails", [
      "setTripBy",
      "setAccessorials",
      "addAccessorial",
      "removeAccessorial",
      "setLinearFeet"
    ]),
    standardize: async function(entity, input, target) {
      try {
        const zipCodeValue = input.value;
        let { data } = await api.getStandardizedEntity(entity, input.value);
        if (zipCodeValue.value !== "" && data[0][1] !== "Not Acceptable") {
          input.value = input.value.toLowerCase();
          let infoCountry = {};
          infoCountry.country = data[0][2];
          infoCountry.target = target.name;
          this.$store.commit("emailDetails/setMapCountry", infoCountry);
          if (entity === "zip_code") {
            this.setTripBy({
              entity: target.name,
              id: this.id,
              value: data[0][1]
            });
            this.setTripBy({
              entity: input.name,
              id: this.id,
              value: data[0][0]
            });
          } else if (entity === "city") {
            if (target.value === "")
              this.setTripBy({
                entity: target.name,
                id: this.id,
                value: data[0][0]
              });
            this.setTripBy({
              entity: input.name,
              id: this.id,
              value: data[0][1]
            });
          }
          return data[0][1];
        } else {
          Swal.fire("Oops...", `Please enter a valid Zipcode`, "warning");
          this.setTripBy({
            entity: input.name,
            id: this.id,
            value: ""
          });
          return "";
        }
      } catch (err) {
        console.error(err.message);
      }
    },
    updateInput: async function(input) {
      input.blur();
      let entity = input.name;
      let standardizeResponse;
      if (entity === "zip_from") {
        let target = this.$el.querySelector("input[name='city_from']");
        standardizeResponse = await this.standardize("zip_code", input, target);
      } else if (entity === "city_from") {
        let target = this.$el.querySelector("input[name='zip_from']");
        standardizeResponse = await this.standardize("city", input, target);
      } else if (entity === "zip_to") {
        let target = this.$el.querySelector("input[name='city_to']");
        standardizeResponse = await this.standardize("zip_code", input, target);
      } else if (entity === "city_to") {
        let target = this.$el.querySelector("input[name='zip_to']");
        standardizeResponse = await this.standardize("city", input, target);
      }
      this.cityInput.value = standardizeResponse;
    },
    accessorialChange: function(target) {
      const category =
        this.category === "pickup" ? "accessorials_from" : "accessorials_to";

      const payload = {
        id: this.id,
        category,
        acc: target.value
      };
      target.checked
        ? this.addAccessorial(payload)
        : this.removeAccessorial(payload);
    }
  },
  mounted: function() {
    // initial standardization
    this.cityInput = document.getElementById(
      `city-from-id${this.id}-${this.category}`
    );
    this.$el.querySelectorAll(".card-input");
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.accessorials-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;

  .card-title {
    flex-basis: 100%;
    padding-top: 10px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__icon {
      margin: 2px;
    }
  }
  .card-meta {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include for-tablet-portrait-up {
      justify-content: space-evenly;
    }

    .card-input {
      min-width: 0;
      flex-basis: 90%;
      margin: 5px 0;
      padding: 3px 10px;
      border: 1px solid #a3a3a3;
      border-radius: 3px;
      color: #6d6868;
      &:focus {
        border-color: var(--button-hover-color);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 5px var(--button-hover-color);
        outline: none;
      }
      &:focus-visible {
        border-color: var(--button-hover-color) !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 5px var(--button-hover-color);
        outline: none;
      }
    }

    .zip-input {
      @include for-tablet-portrait-up {
        flex-basis: 40%;
      }
    }

    .city-input {
      @include for-tablet-portrait-up {
        flex-basis: 50%;
      }
    }
  }

  .card-accessorials-list {
    padding: 10px;
    color: #666666;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    .card-checkbox {
      flex-basis: 50%;
      padding-right: 5px;

      @include for-phone-only {
        flex-basis: 100%;
      }

      @include for-big-desktop-up {
        flex-basis: 25%;
      }
    }
  }
}

.spinner-container {
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
