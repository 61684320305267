<template>
  <div class="trip-info">
    <form class="trip-form" autocomplete="on">
      <span class="trip-legend"> Trip Information </span>
      <div class="trip-content">
        <b-spinner v-show="!loadedAccessorials" class="m-5"> </b-spinner>
        <div class="trip-content__accessorials-cards">
          <AccessorialsCard
            v-show="loadedAccessorials"
            :id="id"
            :editable="editable"
            :currentTrip="currentTrip"
            class="acc-card pickup-card"
            category="pickup"
            :meta="{ zipcode: trip.zip_from, city: trip.city_from }"
            :accessorials="pickupAcc"
          />
          <AccessorialsCard
            v-show="loadedAccessorials"
            :id="id"
            :editable="editable"
            :currentTrip="currentTrip"
            class="acc-card delivery-card"
            category="delivery"
            :meta="{ zipcode: trip.zip_to, city: trip.city_to }"
            :accessorials="deliveryAcc"
          />
        </div>
        <div v-show="loadedAccessorials" class="acc-card general-card">
          <template v-for="(acc, index) in generalAcc">
            <CustomCheckbox
              :key="index"
              :editable="editable"
              :currentTrip="currentTrip"
              :category="'general'"
              class="card-checkbox general-acc"
              :text="acc"
              @change.native="accessorialChange('accessorials', $event.target)"
            />
          </template>
        </div>
        <!-- <b-row class="trip-contentainer" v-if="clientId === 3">
          <b-col md="3"> </b-col>
          
        </b-row> -->
        <b-row class="trip-contentainer">
          <b-col xl="3">
            <b-input
              class="trip-contentainer__input"
              size="sm"
              :state="trip.linear_feet.length == 0 ? null : true"
              :disabled="!editable"
              :value="trip.linear_feet"
              maxlength="3"
              @click="$event.target.select()"
              @keyup.enter="linearFeetChange($event.target)"
              @keydown.tab="linearFeetChange($event.target)"
              @change.native="linearFeetChange($event.target)"
              placeholder="Linear Feet"
            />
          </b-col>
          <b-col xl="3">
            <b-input
              class="trip-contentainer__input"
              size="sm"
              :state="trip.commercial_value.length == 0 ? null : true"
              :disabled="!editable"
              :value="trip.commercial_value"
              @click="$event.target.select()"
              @keyup.enter="commercialValueChange($event.target)"
              @keydown.tab="commercialValueChange($event.target)"
              @change.native="commercialValueChange($event.target)"
              placeholder="Commercial Value"
            />
          </b-col>
          <b-col xl="3" class="mt-xs-2 mt-sm-0">
            <div v-if="clientId !== 3">
              <CustomCheck
                v-if="clientId !== 2"
                :id="`${id}dispatch-request`"
                v-model="dispatchRequest"
              >
                Dispatch Request
              </CustomCheck>
              <CustomCheck v-else :id="`${id}requote`" v-model="requoted">
                Requote
              </CustomCheck>
            </div>
          </b-col>
          <b-col xl="3">
            <div v-if="clientId !== 3">
              <CustomCheck
                v-if="clientId !== 2"
                :id="`${id}tracking-request`"
                v-model="trackingRequest"
              >
                Tracking Request
              </CustomCheck>
              <CustomCheck
                v-else
                :id="`${id}insurance-required`"
                v-model="insuranceRequired"
              >
                Insurance Required
              </CustomCheck>
            </div>
          </b-col>
          <b-col xl="3">
            <div class="equipment">
              <b-form-input
                class="trip-contentainer__input"
                id="input-equipment"
                :disabled="datalistOptions.length !== 0"
                size="sm"
                autocomplete="off"
                list="input-list"
                placeholder="Equipment"
                v-model="datalistOptions"
                @change="selectInputAccessorial()"
              ></b-form-input>
              <datalist id="input-list">
                <option v-for="(acc, index) in datalistAcc" :key="index">
                  {{ acc.label }}
                </option>
              </datalist>
              <b-button
                ref="button"
                id="button-change"
                v-b-popover.hover.rigth="'Change equipment'"
                :disabled="datalistOptions.length === 0"
                variant="primary"
                size="sm"
                class="trip-contentainer__button"
                @click="removeInputAccessorial()"
              >
                <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon
              ></b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import AccessorialsCard from "@/components/AccessorialsCard";
import CustomCheckbox from "@/components/CustomCheckbox";
import CustomCheck from "@/components/CustomCheck.vue";

export default {
  name: "TripInfo",
  components: {
    AccessorialsCard,
    CustomCheckbox,
    CustomCheck
  },
  props: {
    id: Number,
    editable: Boolean
  },
  computed: {
    ...mapGetters("emailDetails", ["getTrip", "selectedEmail"]),
    ...mapState("emailDetails", ["deleteEmptyRow"]),
    trackingRequest: {
      get() {
        return this.trip.tracking_request;
      },
      set(trackingRequest) {
        this.setTrackingRequest({ id: this.id, trackingRequest });
      }
    },
    dispatchRequest: {
      get() {
        return this.trip.dispatch_request;
      },
      set(dispatchRequest) {
        this.setDispatchRequest({ id: this.id, dispatchRequest });
      }
    },
    requoted: {
      get() {
        return this.trip.requoted;
      },
      set(requoted) {
        this.setRequoted({ id: this.id, requoted });
      }
    },
    insuranceRequired: {
      get() {
        return this.trip.insurance_required;
      },
      set(insuranceRequired) {
        this.setInsuranceRequired({ id: this.id, insuranceRequired });
      }
    },
    trip: function() {
      return this.getTrip(this.id);
    }
  },
  data: function() {
    return {
      pickupAcc: [],
      deliveryAcc: [],
      generalAcc: [],
      datalistOptions: "",
      datalistAcc: [
        { label: "Flatbed" },
        { label: "Dedicated FTL" },
        { label: "Straight Truck" },
        { label: "Sprinter Van" },
        { label: "Cargo Van" }
      ],
      clientId: null,
      loadedAccessorials: false,
      currentTrip: {}
    };
  },
  watch: {
    deleteEmptyRow() {
      this.deleteEmptyRow.forEach((rowIndex) => {
        this.$store.dispatch("emailDetails/deleteCommodity", {
          tripId: this.id,
          commodityIndex: rowIndex
        });
      });
      for (let i = this.deleteEmptyRow.length - 1; i >= 0; i--) {
        this.deleteRow(i);
      }
    }
  },
  methods: {
    ...mapMutations("emailDetails", [
      "setTripBy",
      "setAccessorials",
      "addAccessorial",
      "removeAccessorial",
      "setLinearFeet",
      "setCommercialValue",
      "setTrackingRequest",
      "setDispatchRequest",
      "setRequoted",
      "setInsuranceRequired",
      "removeRowFromCommodity"
    ]),
    deleteRow: function(index) {
      this.removeRowFromCommodity({ id: this.id, index });
    },
    linearFeetChange: function(target) {
      target.blur();
      this.setLinearFeet({ id: this.id, lf: target.value });
    },
    commercialValueChange: function(target) {
      target.blur();
      this.setCommercialValue({ id: this.id, value: target.value });
    },
    selectInputAccessorial: function() {
      const equipmmentAcc = this.datalistOptions.toLowerCase();
      this.addAccessorial({
        id: this.id,
        category: "accessorials",
        acc: equipmmentAcc
      });
      document.getElementById("input-equipment").disabled = true;
      document.getElementById("button-change").disabled = false;
    },
    removeInputAccessorial: function() {
      const equipmmentAcc = this.datalistOptions.toLowerCase();
      this.removeAccessorial({
        id: this.id,
        category: "accessorials",
        acc: equipmmentAcc
      });
      document.getElementById("input-equipment").value = "";
      document.getElementById("input-equipment").disabled = false;
      document.getElementById("button-change").disabled = true;
    },
    accessorialChange: function(category, target) {
      target.checked
        ? this.addAccessorial({
            id: this.id,
            category,
            acc: target.value
          })
        : this.removeAccessorial({
            id: this.id,
            category,
            acc: target.value
          });
    },
    checkAccessorials: function(className, acc) {
      this.$el.querySelectorAll(`.${className} input`).forEach((el) => {
        if (acc.includes(el.value)) el.checked = true;
      });
    },
    filterAccessorials: function(category, filterArray) {
      let array = this.trip[category];
      array = array.filter((acc) => filterArray.includes(acc));
      this.setAccessorials({ id: this.id, category, value: array });
    },
    filterNewAccessorials(accessorials) {
      accessorials["general"].forEach((acc) => {
        this.generalAcc.push(acc.name);
      });
      accessorials["delivery"].forEach((acc) => {
        this.deliveryAcc.push(acc.name);
      });
      accessorials["pick-up"].forEach((acc) => {
        this.pickupAcc.push(acc.name);
      });
    }
  },
  /*  mounted: function() {
    // pre-process accessorials
    this.filterAccessorials("accessorials_from", deliveryAccessorials);
    this.filterAccessorials("accessorials_to", pickupAccessorials);
    this.filterAccessorials("accessorials", generalAccessorials);
    // checks accessorials in the frontend
    this.checkAccessorials("pickup-card", this.trip.accessorials_from);
    this.checkAccessorials("delivery-card", this.trip.accessorials_to);
    this.checkAccessorials("general-card", this.trip.accessorials);
  },  */
  async created() {
    this.clientId = this.$store.getters["emailDetails/getClientId"];
    let accesorialsType = ["pick-up", "delivery", "general"];
    let params = {};
    let accessorials = {};
    params.limit = 100;
    params.client_id = localStorage.getItem("client_id");
    for (let i in accesorialsType) {
      params.accessorial_type = accesorialsType[i];
      let response = await this.$store.dispatch(
        "emailDetails/getAccessorials",
        params
      );
      accessorials[accesorialsType[i]] = response;
    }
    this.loadedAccessorials = true;
    this.filterNewAccessorials(accessorials);
    for (let j in this.selectedEmail.trips) {
      if (this.selectedEmail.trips[j].id === this.id) {
        this.currentTrip = this.selectedEmail.trips[j];
        this.$store.commit("emailDetails/setCurrentTrip", this.currentTrip);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  /* padding: 0; */
  box-sizing: border-box;
}

input[type="checkbox"]:checked:focus,
.form-control:focus {
  border-color: var(--button-hover-color);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 5px var(--button-hover-color);
}
::v-deep .pickup-card .card-title {
  color: #79b947;
  text-transform: capitalize;
  font-weight: bold;
}
::v-deep .delivery-card .card-title {
  color: #b94747;
  text-transform: capitalize;
  font-weight: bold;
}
.trip-form {
  margin: 20px 10px;
  padding: 0 12px 10px 12px;
  border-radius: 10px;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .trip-legend {
    margin: 0 auto;
    padding: 6px 0;
    font-size: 1.8em;
    color: #00557e;
    font-weight: bold;
  }

  .acc-card {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 1px 2px 10px 0px #888888;
  }
}

.trip-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  &__accessorials-cards {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  .general-card {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    color: #666666;

    .card-checkbox {
      flex-basis: 50%;

      @include for-desktop-up {
        flex-basis: 18%;
      }
    }
  }
  .equipment {
    display: flex;
    text-align: center;
  }
  .trip-contentainer {
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: 1px 2px 10px 0px #888888;
    &__input {
      margin: 5px 0;
      padding: 3px 10px;
      border-radius: 3px;
      color: #7c7c7c;
    }
    &__button {
      margin-right: 10px;
      margin-top: 4px;
      margin-bottom: 5px;
      margin-left: 5px;
      padding: 5px 5px;
      color: white;
      background-color: #00557e;
      border: none;
      border-radius: 5px;
      box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px,
        rgb(0 0 0 / 19%) 0px 6px 20px 0px;
      &:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }
    }
  }
  .others-card {
    display: flex;
    flex-basis: 100%;
    padding: 0 10px;
    justify-content: center;
    align-items: center;

    .others-label {
      padding: 0 10px;
    }

    .others-input {
      min-width: 0;
      padding: 3px 10px;
      border: 1px solid #ccc;
      border-radius: 3px;
      width: 4rem;
      box-shadow: 1px 1px 0 #999;
    }
  }
}
</style>
