var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"request-form",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"card-header"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true}])},[_c('div',[_c('b-form',[_c('b-form-group',[(_vm.isEmailAssign == 'true')?_c('b-row',[_vm._l((Object.keys(_vm.requestData)),function(key){return [(
                key != 'city' &&
                  key != 'zip_code' &&
                  key != 'country' &&
                  key != 'date' &&
                  key != 'email' &&
                  key != 'ready_time' &&
                  key != 'close_time'
              )?_c('b-col',{key:key,attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-form-input',{staticClass:"request-section__input",attrs:{"type":"text","size":"sm","label":key,"placeholder":_vm._f("removeUnderscores")(key),"value":_vm.requestData[key],"disabled":!_vm.editable},on:{"blur":function($event){return _vm.updateValue(key, $event.target.value)}}})],1):_vm._e(),(key == 'close_time' || key == 'ready_time')?_c('b-col',{key:key,attrs:{"sm":"12","lg":"4"}},[(
                  (_vm.title != 'Delivery Request Info' && _vm.clientId != '3') ||
                    _vm.clientId == '3' ||
                    _vm.clientId == '5'
                )?_c('b-form-input',{staticClass:"request-section__input",attrs:{"type":"time","placeholder":key == 'close_time' ? 'Close Time' : 'Ready Time',"size":"sm","disabled":!_vm.editable},on:{"input":function($event){return _vm.updateValue(key, $event)}},model:{value:(_vm.value[key]),callback:function ($$v) {_vm.$set(_vm.value, key, $$v)},expression:"value[key]"}}):_vm._e()],1):_vm._e(),(key == 'email')?_c('b-col',{key:key,attrs:{"sm":"12","lg":"8"}},[_c('b-form-input',{staticClass:"request-section__input",attrs:{"size":"sm","state":_vm.emailFieldState,"label":key,"placeholder":_vm._f("removeUnderscores")(key),"value":_vm.requestData[key],"disabled":!_vm.editable},on:{"blur":function($event){return _vm.updateValue(key, $event.target.value)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1):_vm._e(),(key == 'country')?_c('b-col',{key:key,attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-form-input',{staticClass:"request-section__input",attrs:{"placeholder":"Country","size":"sm","list":"my-list-id","autocomplete":"off","disabled":!_vm.editable},on:{"click":_vm.cleanCountry},nativeOn:{"input":function($event){return _vm.updateValue(key, $event.target.value)}},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('datalist',{attrs:{"id":"my-list-id"}},_vm._l((_vm.options),function(country,index){return _c('option',{key:key + index},[_vm._v(" "+_vm._s(country)+" ")])}),0)],1):_vm._e(),(
                (key == 'date' &&
                  _vm.title != 'Delivery Request Info' &&
                  _vm.clientId != '3') ||
                  (key == 'date' && _vm.clientId == '3') ||
                  (key == 'date' && _vm.clientId == '5')
              )?_c('b-col',{key:key,staticClass:"col-sm-4"},[_c('b-form-input',{staticClass:"mb-2 request-section__datepicker",attrs:{"type":"date","size":"sm","disabled":!_vm.editable},on:{"input":function($event){return _vm.updateValue(key, $event)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e()]})],2):_vm._e(),(_vm.isEmailAssign == 'false')?_c('b-row',[_vm._l((Object.keys(_vm.requestData)),function(key){return [_c('b-col',{key:key,attrs:{"sm":"12","md":"6","lg":"4"}},[_c('b-form-input',{staticClass:"request-section__input",attrs:{"type":"text","placeholder":_vm._f("removeUnderscores")(key),"size":"sm","value":_vm.requestData[key],"label":key,"disabled":!_vm.editable}})],1)]})],2):_vm._e(),(
            _vm.title == 'Delivery Request Info' &&
              _vm.clientId != '3' &&
              _vm.clientId != '5'
          )?_c('b-row',{staticStyle:{"height":"46px"}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }