<template>
  <div class="cannot-quote">
    <b-form-group label="Why is it not possible to quote?">
      <b-form-textarea
        id="textarea-small"
        v-model="descriptionNotQuote"
        placeholder="Enter more than 3 characters"
        :state="descriptionNotQuote.length <= 3 ? null : true"
      ></b-form-textarea>
      <div class="option-button">
        <OptionButton
          class="option-button__cannot-quote"
          title="Can't quote"
          @click.native="canNotQuote"
          :disabled="descriptionNotQuote.length <= 3 ? editable : !editable"
          style="color: gray !important"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import OptionButton from "./OptionButton";

export default {
  name: "CannotQuote",
  props: {
    selectedEmail: Object
  },
  components: {
    OptionButton
  },
  data() {
    return {
      incomplete: true,
      notQuote: false,
      descriptionNotQuote: "",
      editable: true,
      activeColor: "blue"
    };
  },
  methods: {
    async canNotQuote() {
      this.editable = false;
      this.notQuote = true;
      let info = {
        client_id: this.$store.getters["emailDetails/getClientId"],
        canned_id: this.$store.getters["emailDetails/getCannedId"],
        hauls: this.selectedEmail.trips,
        incomplete: this.incomplete,
        custom_id: this.selectedEmail.id,
        not_quote: this.notQuote,
        description_not_quote: this.descriptionNotQuote
      };
      await this.$store.dispatch("quote/canNotQuote", info);
    }
  }
};
</script>

<style>
.cannot-quote {
  width: 100%;
}

.option-button {
  display: flex;
  justify-content: start;
  margin-top: 1rem;
}
::v-deep .option-button__cannot-quote {
  background-color: red;
}
</style>
