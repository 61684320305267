<template>
  <div class="header">
    <p>
      <strong>{{ title }}</strong>
    </p>
    <div>
      <h3><strong>ID:</strong>{{ id }}</h3>
      <h4><strong>SUBJECT:</strong> {{ subject }}</h4>
    </div>
    <div class="container-img-glt">
      <img v-if="clientId == 2" src="../assets/GLT-LOGISTICS.svg" alt="GLT" />
      <img v-if="clientId == 3" src="../assets/pilotLogo.png" alt="GLT" />
    </div>
    <div class="header__pac-alert" v-if="showPacAlert">
      <b-alert class="header__pac-alert" v-if="showPacAlert" show variant="warning">Si no hay información de la carga, por favor cotice como Full Truck!</b-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TheHeader",
  props: {
    title: String,
    subject: String,
    id: String
  },
  data(){
    return{
      showPacAlert: false
    }
  },
  computed: {
    ...mapState({
      clientId: (state) => state.emailDetails.clientId
    })
  },

  created(){
    this.id.includes('PAC') ? this.showPacAlert = true : this.showPacAlert = false
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  padding: 20px 15px;
  background-position: bottom;
  background-size: 100% auto;
  background-repeat: repeat-y;
  color: #666666;
  &__pac-alert{
    padding:.75rem 1.25rem;
  }
}
p {
  color: #a3a3a3;
}
img {
  width: 8rem;
  padding-top: 1.5rem;
}
h4,
h3 {
  padding-top: 10px;
  color: #af71b3;
}
</style>
