<template>
  <div id="toggle-switch-wrapper">
    <input
      class="toggle-switch"
      type="checkbox"
      :checked="value"
      @input="updateCheck($event.target.checked)"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: "ToggleSwitch",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    value: Boolean
  },
  methods: {
    updateCheck(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#toggle-switch-wrapper {
  position: relative;
  height: 25px;
  width: 40px;
}

.toggle-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 40px;
  height: 100%;
  background-color: #a3a3a3;
  outline: 0;
  border-radius: 20px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:checked {
    background-color: var(--app-font-color);

    &:before {
      left: 20px;
      background-color: #ffffff;
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #eceff1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }
}
</style>
