<template>
  <div class="trip-panel" :class="{ collapsed: isCollapsed }" :key="keyRender">
    <div>
      <b-overlay
        :show="isCreating"
        rounded
        opacity="0.6"
        spinner-small
        bg-color="#ebecec"
        class="d-inline-block"
      >
        <b-button variant="outline-succes" v-if="editable" @click="addPanel"
          >Add Trip</b-button
        >
      </b-overlay>
      <b-button variant="outline-danger" v-if="editable" @click="dropPanel"
        >Delete Trip</b-button
      >
    </div>
    <div class="top-section">
      <TripInfo class="trip-info" :id="id" :editable="editable" />
      <!--  <GltForm :id="id" v-if="!showForm" class="trip-info"/> -->
      <!-- <div class="buttons-section">
        <button
          class="side-button toggle-button"
          @click="togglePanel($event.target)"
        >
          &#x25B2;
        </button>
      </div> -->
    </div>
    <div class="request-forms" v-if="showRequestForm">
      <div class="request-forms__pickup">
        <RequestForm
          title="Pickup Request Info"
          :mapCountry="countryFrom"
          class="mb-2 mt-3 request-section__pickup"
          v-if="pickupRequest !== null"
          :trip="trip"
          v-model="pickupRequest"
          :editable="editable"
        />
      </div>
      <div class="request-forms__delivery">
        <RequestForm
          :mapCountry="countryTo"
          title="Delivery Request Info"
          class="mb-2 mt-3 request-section__delivery"
          v-if="deliveryRequest !== null"
          :trip="trip"
          v-model="deliveryRequest"
          :editable="editable"
        />
      </div>
    </div>
    <!--    <b-container class="request-section mx-auto">
      </b-container> -->
    <div class="table-wrapper">
      <DetailsTable :editable="editable" :id="id" :index="indexTrip" />
    </div>
    <div v-if="clientId == 2" class="aditional-info-trip">
      <label>NMFC Class:</label>
      <b-form-select
        v-model="densityClass"
        :options="nmfcClassOptions"
        @input="setDensityClass"
        class="density-class"
        :disabled="!editable"
      >
      </b-form-select>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import TripInfo from "@/components/TripInfo.vue";
import DetailsTable from "@/components/DetailsTable.vue";
import RequestForm from "@/components/RequestForm.vue";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
// import GltForm from "./GltForm.vue";

export default {
  name: "TripPanel",
  props: {
    id: Number,
    type: String,
    editable: Boolean,
    deleteTab: Number,
    index: Number
  },
  components: {
    TripInfo,
    DetailsTable,
    RequestForm
    // GltForm
  },
  data: function() {
    return {
      isCollapsed: false,
      keyRender: 0,
      countryFrom: null,
      countryTo: null,
      isCreating: false,
      showRequestForm: true,
      densityClass: [],
      nmfcClassOptions: [
        { value: null, text: "Please select an option" },
        { value: 500, text: "500" },
        { value: 400, text: "400" },
        { value: 300, text: "300" },
        { value: 250, text: "250" },
        { value: 200, text: "200" },
        { value: 175, text: "175" },
        { value: 150, text: "150" },
        { value: 125, text: "125" },
        { value: 110, text: "110" },
        { value: 100, text: "100" },
        { value: 92.5, text: "92.5" },
        { value: 85, text: "85" },
        { value: 77.5, text: "77.5" },
        { value: 70, text: "70" },
        { value: 65, text: "65" },
        { value: 60, text: "60" },
        { value: 55, text: "55" },
        { value: 50, text: "50" }
      ],
      indexTrip: this.index,
      clienId: null
    };
  },
  watch: {
    mapCountry(infoCountry) {
      infoCountry.target === "city_from" || infoCountry.target === "zip_from"
        ? (this.countryFrom = infoCountry.country)
        : (this.countryTo = infoCountry.country);
    }
  },
  computed: {
    ...mapGetters("emailDetails", [
      "selectedEmail",
      "getTrip",
      "pickupRequestByTrip",
      "deliveryRequestByTrip"
    ]),
    ...mapState("emailDetails", ["mapCountry"]),
    trip() {
      return this.getTrip(this.id);
    },
    pickupRequest: {
      get() {
        return this.pickupRequestByTrip(this.id);
      },
      set(value) {
        this.setPickupRequest(value);
      }
    },
    deliveryRequest: {
      get() {
        return this.deliveryRequestByTrip(this.id);
      },
      set(value) {
        this.setDeliveryRequest(value);
      }
    }
  },
  methods: {
    ...mapActions("emailDetails", ["addTrip", "deleteTrip"]),
    ...mapMutations("emailDetails", [
      "setPickupRequest",
      "setDeliveryRequest",
      "setDispatchRequest"
    ]),
    async addPanel() {
      if (this.editable) {
        this.isCreating = true;
        await this.addTrip(this.selectedEmail.id);
        this.isCreating = false;
      } else {
        Swal.fire(
          "Oops...",
          `You cannot add trips in ${this.type} mode`,
          "error"
        );
      }
    },
    async dropPanel() {
      if (this.editable) {
        Swal.fire({
          icon: "warning",
          title: "Are you sure you want to delete this panel?",
          text: "You won't be able to revert this!",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.value) {
            this.deleteTrip(this.trip.id).then(() =>
              Swal.fire("Deleted!", "Trip has been deleted.", "success")
            );
          }
        });
      } else
        Swal.fire(
          "Oops...",
          `You cannot delete trips in ${this.type} mode`,
          "error"
        );
    },
    async setDensityClass() {
      if (this.densityClass !== null && this.densityClass !== "") {
        this.selectedEmail.trips[this.index].density_class = this.densityClass;
      }
    },
    togglePanel: function(button) {
      let panel = this.$el.querySelector(".table-wrapper");
      if (panel.style.maxHeight === "0px") {
        panel.style.maxHeight = panel.scrollHeight + "px";
        button.innerHTML = "&#x25B2;";
        this.isCollapsed = false;
      } else {
        panel.style.maxHeight = "0";
        button.innerHTML = "&#x25BC;";
        this.isCollapsed = true;
      }
    }
  },
  created() {
    if (this.selectedEmail.trips[this.index].density_class !== null) {
      this.densityClass = this.selectedEmail.trips[this.index].density_class;
    }
    this.clientId = this.$store.getters["emailDetails/getClientId"];
    let clienId = localStorage.getItem("client_id");
    if (
      clienId === "1" ||
      clienId === "3" ||
      clienId === "4" ||
      clienId === "5"
    ) {
      this.showRequestForm = true;
    } else {
      this.showRequestForm = false;
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.trip-info {
  margin-bottom: 15px;
}

.top-section {
  display: flex;
  justify-content: center;
}

.request-forms {
  display: flex;
  flex-direction: row;
  @media (max-width: 1099px) {
    flex-direction: column;
  }
}

.buttons-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > button.side-button {
    background-color: var(--app-font-color);
    margin: 5px 0 5px 10px;
    display: inline-block;
    padding: 2px 4px;
    outline: none;
  }
}
::v-deep .btn-outline-succes {
  margin: 10px;
  color: white;
  padding: 2px;
  border-color: #28a745;
  background: #28a745;
  border-radius: 7px;
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  outline: none;

  &:enabled {
    transition: all 0.1s ease;

    &:hover {
      background-color: var(--button-hover-color);
      border-color: var(--button-hover-color);
      transform: scale(1.05);
    }
  }
}

.plus-button,
.minus-button {
  color: #ffffff;
  font-weight: bold;
  border: none;
  transition: transform 0.1s;

  &:hover {
    transform: scale(1.05);
  }
}

button.side-button.toggle-button {
  padding: 0;
  font-size: 1.5em;
  background-color: transparent;
  color: #292929;
  border-style: none;
}

.table-wrapper {
  max-height: 2000px;
  overflow: hidden;
  transition: max-height 0.5s;
  box-shadow: 1px 2px 10px 0px #888888;
  border-radius: 3px;
}

.aditional-info-trip {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  label {
    white-space: nowrap;
    margin-right: 10px;
    font-size: 14px;
  }
  select {
    width: 15rem;
  }
}
</style>
