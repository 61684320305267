<template>
  <div class="table-wrapper">
    <b-overlay
      :show="!loadedAccessorials"
      rounded
      opacity="0.6"
      bg-color="#ebecec"
      class="d-inline-block"
    >
      <header class="table-header">
        <OptionButton
          v-if="editable"
          title="delete rows"
          @click.native="deleteRows"
        />
        <h3 class="details" v-else>Details</h3>
        <OptionButton
          v-if="editable"
          title="add new row"
          @click.native="addRow(id)"
        />
      </header>
      <table class="table">
        <thead class="thead">
          <tr>
            <th v-if="editable">
              <CustomCheckbox
                class="delete-icon checkbox"
                @change.native="checkAllRows($event.target.checked)"
              />
            </th>
            <th v-else></th>
            <th :key="index" v-for="(column, index) in schema">
              {{ column | removeUnderscores | uppercase }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :key="index" v-for="(row, index) in trip.commodity">
            <td v-if="editable">
              <CustomCheckbox class="delete-icon checkbox checkbox-item" />
            </td>
            <td v-else></td>
            <td :key="entity" v-for="(columnValue, entity) in filter(row)">
              <textarea
                class="table-field"
                :class="entity"
                :disabled="
                  entity == ('contribution' || entity == 'nmfc_calculated') ||
                    !editable
                "
                :value="row[entity]"
                @click="$event.target.select()"
                @keydown.enter="updateColumnValue(index, entity, $event.target)"
                @keydown.tab="updateColumnValue(index, entity, $event.target)"
                @change="resizeFields"
                @keydown="resizeFields"
              >
              </textarea>
            </td>
          </tr>
          <tr>
            <td class="totals">
              {{ "totals" | removeUnderscores | uppercase }}
            </td>
            <td :key="index" class="totals" v-for="(entity, index) in totals">
              <input
                :disabled="!editable"
                v-if="index == 'weight'"
                class="totals-item with-border"
                :value="totals[index]"
                @click="$event.target.select()"
                @keydown.enter="updateAllColumns($event.target)"
                @keydown.tab="updateAllColumns($event.target)"
              />
              <label
                class="totals-item"
                v-if="index != 'weight' && index != 'nmfc'"
                >{{ entity }}</label
              >
              <div v-if="index == 'nmfc'" class="nmfc-content">
                <b-overlay
                  :show="addingLinearFeet"
                  rounded
                  opacity="0.6"
                  spinner-small
                  class="d-inline-block"
                >
                  <b-button
                    @click="calculateLinearFeet()"
                    class="calculate-linear-feet"
                    variant="info"
                    ><i class="fa fa-calculator"></i
                  ></b-button>
                </b-overlay>
                <input
                  class="totals-item with-border"
                  :disabled="!editable"
                  v-model="linearFeet"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </b-overlay>
  </div>
</template>

<script>
import * as api from "@/api/services";
import * as helpers from "@/utils/helpers";
import OptionButton from "@/components/OptionButton.vue";
import CustomCheckbox from "@/components/CustomCheckbox.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "DetailsTable",
  props: {
    id: Number,
    editable: Boolean,
    index: Number
  },
  components: {
    OptionButton,
    CustomCheckbox
  },
  computed: {
    ...mapState("emailDetails", ["selectedEmail"]),
    ...mapGetters("emailDetails", ["getTrip"]),
    ...mapState("emailDetails", ["flagAddCommodity", "disabledAccesorials"]),
    trip: function() {
      return this.getTrip(this.id);
    },
    schema: function() {
      return [
        "hu_count",
        "dimensions",
        "weight",
        "contribution",
        "nmfc_calculated",
        "nmfc",
        "description"
      ];
    },
    dataArrays: function() {
      let data = {
        hu_count: [],
        dimensions: [],
        weight: [],
        contribution: []
      };
      //enters after validation of empty string
      for (let row of this.trip.commodity) {
        if (row.hu_count !== "") data.hu_count.push(row.hu_count);
        if (row.dimensions !== "") data.dimensions.push(row.dimensions);
        if (row.weight !== "") data.weight.push(row.weight);
        if (row.contribution !== "") data.contribution.push(row.contribution);
      }
      return data;
    }
  },
  data: function() {
    return {
      totals: {
        hu_count: "--",
        dimensions: "--",
        weight: "--",
        contribution: "",
        nmfc_calculated: "",
        nmfc: "",
        description: ""
      },
      pilotAccesorial: "",
      loadedAccessorials: false,
      currentTrip: [],
      addingLinearFeet: false,
      linearFeet: ""
    };
  },
  watch: {
    flagAddCommodity() {
      let currentTrip = this.$store.getters["emailDetails/selectedEmail"];
      for (let i in currentTrip.trips) {
        if (currentTrip.trips[i].id === this.id) {
          this.currentTrip = currentTrip.trips[i];
        }
      }
      this.standardizeColumns();
    },
    disabledAccesorials(acc) {
      this.pilotAccesorial = acc;
      this.addRow(this.id);
    },
    linearFeet() {
      this.selectedEmail.trips[this.index].linear_feet = this.linearFeet;
    }
  },
  methods: {
    ...mapActions("emailDetails", ["deleteCommodity", "addCommodity"]),
    ...mapMutations("emailDetails", [
      "addRowToCommodity",
      "removeRowFromCommodity",
      "setContributions",
      "setEntityValue"
    ]),
    filter: function(row) {
      let filteredRow = {
        hu_count: row.hu_count,
        dimensions: row.dimensions,
        weight: row.weight,
        contribution: row.contribution,
        nmfc_calculated: row.nmfc_calculated,
        nmfc: row.nmfc,
        description: row.description
      };
      return filteredRow;
    },
    async calculateLinearFeet() {
      this.addingLinearFeet = true;
      let dataLinearFeet = [];
      let stackable = false;
      if (this.trip.accessorials.includes("stackable")) {
        stackable = true;
      }
      for (let i in this.trip.commodity) {
        let data = {};
        data.hu_count = this.trip.commodity[i].hu_count;
        data.dimensions = this.trip.commodity[i].dimensions;
        data.weight = this.trip.commodity[i].weight;
        data.stackable = stackable;
        dataLinearFeet.push(data);
      }
      let payload = {
        body: dataLinearFeet,
        container: "ltl_dry_van_53"
      };
      let response = await this.$store.dispatch(
        "emailDetails/getLinearFeet",
        payload
      );
      this.addingLinearFeet = false;
      this.linearFeet = response;
    },
    deleteRow: function(index) {
      let clientId = localStorage.getItem("client_id");
      this.removeRowFromCommodity({ id: this.id, index });
      //if table is empty adds a new row to it
      if (this.trip.commodity.length === 0 && clientId != "3") {
        this.addRow(this.id);
        this.resetStyles();
      } else this.updateContributions();
    },
    async addRow(id) {
      let infoNewRow = {};
      infoNewRow.id = id;
      infoNewRow.isNewTrip = false;
      infoNewRow.pilotAcc = this.pilotAccesorial;
      this.addCommodity(infoNewRow);
      this.pilotAccesorial = "";
    },
    deleteRows: async function() {
      let checkboxes = this.$el.querySelectorAll(".checkbox-item > input");
      for (let i = checkboxes.length - 1; i >= 0; i--) {
        if (checkboxes[i].checked) {
          await this.deleteCommodity({ tripId: this.id, commodityIndex: i });
          this.deleteRow(i);
        }
      }
      this.checkAllRows(false);
      this.getTotals();
    },
    checkAllRows: function(state) {
      let checkboxes = this.$el.querySelectorAll(".checkbox-item > input");
      checkboxes.forEach((element) => {
        element.checked = state;
      });
    },
    updateContributions: async function() {
      if (
        helpers.validArrays(
          this.dataArrays["hu_count"],
          this.dataArrays["dimensions"]
        )
      ) {
        let volume = await api.getTotalVolume(
          this.dataArrays.dimensions,
          this.dataArrays.hu_count
        );
        let contr = await api.getContributions(
          this.dataArrays.dimensions,
          this.dataArrays.hu_count,
          volume.data
        );
        this.setContributions({ id: this.id, contr: contr.data });
      }
    },
    updateWeights: async function(totalWeight) {
      const { data } = await api.getWeightsFromContributions(
        totalWeight,
        this.dataArrays.contribution
      );
      data.forEach((weight, index) => {
        this.setEntityValue({
          id: this.id,
          index,
          entity: "weight",
          entityValue: weight
        });
      });
    },
    updateNMFC: async function(row, index) {
      const { data } = await api.getNMFC(row);
      this.setEntityValue({
        id: this.id,
        index,
        entity: "nmfc_calculated",
        entityValue: data.nmfc
      });
    },
    updateAllNMFC: function() {
      this.trip.commodity.forEach(async (row, index) => {
        this.updateNMFC(row, index);
      });
    },
    updateAllColumns: async function(input) {
      input.blur();
      if (input.value !== "") {
        let result = helpers.processInputValue(input.value);
        try {
          let { data } = await api.getStandardizedEntity("weight", result);
          this.totals["weight"] = data[0];
          this.updateContributions();
          await this.updateWeights(data[0]);
          this.updateAllNMFC();
        } catch (err) {
          console.error(err.message);
        }
      }
    },
    standardizeColumns() {
      let entityes = ["hu_count", "dimensions", "weight"];
      for (let i in this.currentTrip.commodity) {
        let currentCommodity = this.currentTrip.commodity[i];
        for (let j in entityes) {
          this.updateColumnValueNewTrip(
            i,
            entityes[j],
            currentCommodity[entityes[j]]
          );
        }
      }
    },
    updateColumnValueNewTrip: async function(index, entity, value) {
      let entityValue = value;
      //process entities with calculator before sending it to backend
      let processableEntities = ["hu_count", "weight"];
      if (processableEntities.includes(entity) && entityValue !== "")
        entityValue = helpers.processInputValue(entityValue);
      try {
        if (entity !== "nmfc") {
          if (entityValue !== "") {
            const standardized = await api.getStandardizedEntity(
              entity,
              entityValue
            );
            //postprocess entityValue
            entityValue = helpers.removeEOL(standardized.data[0]);
          }
        }
        this.setEntityValue({
          id: this.id,
          index,
          entity,
          entityValue
        });
        //Each of the entities is separated to avoid requests for more
        if (["hu_count"].includes(entity)) {
          this.getNewTotalHuCount();
        }
        if (["dimensions"].includes(entity)) {
          this.getNewTotalVolume();
        }
        if (["weight"].includes(entity)) {
          this.getNewTotalWeight();
        }
        await this.updateNMFC(this.trip.commodity[index], index);
        //change contributions
        if (entity !== "weight") this.updateContributions();
      } catch (err) {
        console.error(err.message);
      }
    },
    updateColumnValue: async function(index, entity, target) {
      target.blur();
      let entityValue = target.value;
      //process entities with calculator before sending it to backend
      let processableEntities = ["hu_count", "weight"];
      if (processableEntities.includes(entity) && entityValue !== "")
        entityValue = helpers.processInputValue(entityValue);
      try {
        if (entity !== "nmfc") {
          if (entityValue !== "") {
            const standardized = await api.getStandardizedEntity(
              entity,
              entityValue
            );
            //postprocess entityValue
            entityValue = helpers.removeEOL(standardized.data[0]);
            this.changeColorAlert(target, standardized.data[1]);
          }
        }
        this.setEntityValue({
          id: this.id,
          index,
          entity,
          entityValue
        });
        //Each of the entities is separated to avoid requests for more
        if (["hu_count"].includes(entity)) {
          this.getNewTotalHuCount();
        }
        if (["dimensions"].includes(entity)) {
          this.getNewTotalVolume();
        }
        if (["weight"].includes(entity)) {
          this.getNewTotalWeight();
        }
        await this.updateNMFC(this.trip.commodity[index], index);
        //change contributions
        if (entity !== "weight") this.updateContributions();
      } catch (err) {
        console.error(err.message);
      }
    },
    //Each of the entities is separated to avoid requests for more
    getNewTotalHuCount: async function() {
      const totalHuCountResponse = await api.getTotalHuCount(
        this.dataArrays.hu_count
      );
      this.totals.hu_count =
        totalHuCountResponse.data !== "0" ? totalHuCountResponse.data : "--";
      this.selectedEmail.trips[
        this.index
      ].total_hu_count = this.totals.hu_count;
    },
    getNewTotalVolume: async function() {
      const totalVolumeResponse = await api.getTotalVolume(
        this.dataArrays.dimensions,
        this.dataArrays.hu_count
      );
      this.totals.dimensions =
        totalVolumeResponse.data !== "" ? totalVolumeResponse.data : "--";
      this.selectedEmail.trips[this.index].cbf = this.totals.dimensions;
    },
    getNewTotalWeight: async function() {
      const totalWeightResponse = await api.getTotalWeight(
        this.dataArrays.weight
      );
      this.totals.weight =
        totalWeightResponse.data !== "0" ? totalWeightResponse.data : "--";
      this.selectedEmail.trips[this.index].total_weight = this.totals.weight;
    },
    getTotals: async function() {
      try {
        //get total weight (lb)
        const totalWeightResponse = await api.getTotalWeight(
          this.dataArrays.weight
        );
        this.totals.weight =
          totalWeightResponse.data !== "0" ? totalWeightResponse.data : "--";
        this.selectedEmail.trips[this.index].total_weight = this.totals.weight;
        //get total volume (CBF)
        const totalVolumeResponse = await api.getTotalVolume(
          this.dataArrays.dimensions,
          this.dataArrays.hu_count
        );
        this.totals.dimensions =
          totalVolumeResponse.data !== "" ? totalVolumeResponse.data : "--";
        this.selectedEmail.trips[this.index].cbf = this.totals.dimensions;
        //get total hu count (HU)
        const totalHuCountResponse = await api.getTotalHuCount(
          this.dataArrays.hu_count
        );
        this.totals.hu_count =
          totalHuCountResponse.data !== "0" ? totalHuCountResponse.data : "--";
        this.selectedEmail.trips[
          this.index
        ].total_hu_count = this.totals.hu_count;
        this.loadedAccessorials = true;
      } catch (err) {
        console.error(err.message);
      }
    },
    resizeFields: function() {
      let maxHeight = 0;
      let inputs = this.$el.querySelectorAll(".table-field");
      inputs.forEach((element) => {
        element.style.height = "auto";
        if (element.scrollHeight > maxHeight) maxHeight = element.scrollHeight;
      });
      inputs.forEach((element) => {
        element.style.height = `${maxHeight}px`;
      });
    },
    changeColorAlert: function(target, colorCode) {
      target.style.borderBottomStyle = "none";
      if (target.value !== "") {
        target.style.borderBottomStyle = "solid";
        target.style.borderBottomColor = this.getColorAlert(colorCode);
      }
    },
    getColorCode: function(entityValue, entity) {
      let entityCode = -1;
      if (entity !== "dimensions") {
        const probabilityInfo = this.selectedEmail.probability;
        Object.keys(probabilityInfo).forEach((key) => {
          let elements = probabilityInfo[key];
          elements.forEach((element) => {
            if (key === entity && element[4] === entityValue) {
              entityCode = element[5];
            }
          });
        });
      }
      return entityCode;
    },
    getColorAlert: function(entityCode) {
      let color = "";
      switch (entityCode) {
        case 0:
          color = "red";
          break;
        case 1:
          color = "yellow";
          break;
        case 2:
          color = "green";
          break;
        case 4:
          color = "gray";
          break;
        default:
          color = "#fafafa";
          break;
      }
      return color;
    },
    getStyle: function(entityValue, entity) {
      let style = "none";
      let entityCode = this.getColorCode(entityValue, entity);
      if ([0, 1, 2].includes(entityCode)) style = "solid";
      return style;
    },
    resetStyles: function() {
      for (const entity of this.schema) {
        const fields = this.$el.querySelectorAll(`.table-field.${entity}`);
        fields.forEach(
          (textarea) => (textarea.style.borderBottomStyle = "none")
        );
      }
    },
    setFieldStyles: function() {
      for (const entity of this.schema) {
        const fields = this.$el.querySelectorAll(`.table-field.${entity}`);
        fields.forEach((textarea) => {
          textarea.style.borderBottomStyle = this.getStyle(
            textarea.value,
            entity
          );
          const colorCode = this.getColorCode(textarea.value, entity);
          textarea.style.borderBottomColor = this.getColorAlert(colorCode);
        });
      }
    },
    deleteEmptyRows() {
      let cliendId = localStorage.getItem("client_id");
      if (cliendId === "3") {
        this.$store.dispatch("emailDetails/deleteEmptyRows", this.id);
      }
    }
  },

  async mounted() {
    await this.deleteEmptyRows();
    this.setFieldStyles();
    this.getTotals();
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.table-wrapper {
  overflow: auto !important;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #00557e;
    border-radius: 3px;
  }
}
.nmfc-content {
  display: flex;
  justify-content: center;
}
.table-header {
  color: #00557e;
  background: #ebebeb;
  border-bottom: 1px solid #bcbcbc;
  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.table {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #ffffff;

  @include for-desktop-down {
    table-layout: fixed;
  }
}

.table-header,
.table {
  box-shadow: 5px 10px 8px #888888;
}

th,
td {
  text-align: center;
  padding: 8px;
  font-size: 0.85em;
  word-wrap: break-word;
}

.table th {
  font-size: 0.7em;
  border-top: none;
  border-bottom: none;
}

th,
td {
  padding: 8px 15px;
}

tbody tr:nth-child(even) {
  background-color: #fcfcfc;
}

tbody tr:hover {
  background-color: #f3f3f3;
}

.thead {
  color: #00557e;
  background: #ebebeb;
  border-bottom: 1px solid #bcbcbc;
}

textarea {
  color: black;
  resize: none;
  overflow: hidden;
  height: auto;
}

textarea,
.totals-item {
  background: transparent;
  border: none;
  width: 100%;
  text-align: center;
  &:focus-visible {
    border-color: var(--button-hover-color) !important;
    outline: none;
  }
  &:focus {
    border-color: var(--button-hover-color);
    outline: none;
  }
}

.details {
  margin: 5px;
}

.with-pointer {
  cursor: pointer;
}

.delete-icon {
  color: var(--table-delete-icon-color);
  cursor: pointer;

  &:hover {
    color: var(--table-delete-icon-hover-color);
  }
}

.delete-icon-column {
  width: 50px;
}

.totals {
  padding-top: 15px;
  font-weight: 700;
  color: #1e324b;
}

.totals-item {
  font-weight: 700;
  color: #1e324b;
}

.with-border {
  border: 1px solid #cfd8dc;
  border-radius: 5px;
}

.calculate-linear-feet {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  height: 95%;
}
</style>
